<template>
<div>
    <v-app>
        <div class="row">
            <div class="col">
                <v-card>
                    <v-card-title>
                        <span>Pedido #{{orden_id}}</span>
                    </v-card-title>
                    <v-card-text>
                        <div class="row">
                            <div class="col">
                                <div class="kt-portlet">
                                    <div class="kt-portlet__body  kt-portlet__body--fit">
                                        <div class="row row-no-padding row-col-separator-xl">
                                            <div class="col-sm-12 col-md-4 col-lg-2 col-xl-2">
                                                <v-card height="131px" outlined tile >
                                                    <v-card-text style="height: 100%" class="d-flex flex-column justify-space-between">
                                                        <div class="d-flex flex-column">
                                                            <span class="text-subtitle-1 font-weight-medium">Número de pedido</span>
                                                            <span class="text-body-2">Interno</span>
                                                        </div>
                                                        <span style="font-size: 1.2rem !important;" class="text-subtitle-1 kt-font-brand font-weight-medium">
                                                            {{orden_id}}
                                                        </span>
                                                    </v-card-text>
                                                </v-card>
                                            </div>
                                            <div class="col-sm-12 col-md-4 col-lg-2 col-xl-2">
                                                <v-card height="131px" outlined tile >
                                                    <v-card-text style="height: 100%" class="d-flex flex-column justify-space-between">
                                                        <div class="d-flex flex-column">
                                                            <span class="text-subtitle-1 font-weight-medium">Referencia</span>
                                                            <span class="text-body-2">Fuente</span>
                                                        </div>
                                                        <span style="font-size: 1.2rem !important;" class="text-subtitle-1 kt-font-warning font-weight-medium">
                                                            {{order.order_reference}}
                                                        </span>
                                                    </v-card-text>
                                                </v-card>
                                            </div>
                                            <div class="col-sm-12 col-md-4 col-lg-2 col-xl-2">
                                                <v-card height="131px" outlined tile >
                                                    <v-card-text style="height: 100%" class="d-flex flex-column justify-space-between">
                                                        <div class="d-flex flex-column">
                                                            <span class="text-subtitle-1 font-weight-medium">Fuente</span>
                                                            <span class="text-body-2">Tienda/Marketplace</span>
                                                        </div>
                                                        <span style="font-size: 1.2rem !important;" class="text-subtitle-1 kt-font-danger font-weight-medium">
                                                            {{source.name}}
                                                        </span>
                                                    </v-card-text>
                                                </v-card>
                                            </div>
                                            <div class="col-sm-12 col-md-4 col-lg-2 col-xl-2">
                                                <v-card height="131px" outlined tile >
                                                    <v-card-text style="height: 100%" class="d-flex flex-column justify-space-between">
                                                        <div class="d-flex flex-column">
                                                            <span class="text-subtitle-1 font-weight-medium">Fecha</span>
                                                            <span class="text-body-2">Ingreso</span>
                                                        </div>
                                                        <span style="font-size: 1.2rem !important;" class="text-subtitle-1 kt-font-success font-weight-medium">
                                                            {{$moment(order.source_date).format('DD-MM-YYYY HH:mm')}}
                                                        </span>
                                                    </v-card-text>
                                                </v-card>
                                            </div>
                                            <div class="col-sm-12 col-md-4 col-lg-2 col-xl-2">
                                                <v-card height="131px" outlined tile >
                                                    <v-card-text style="height: 100%" class="d-flex flex-column justify-space-between">
                                                        <div class="d-flex flex-column">
                                                            <span class="text-subtitle-1 font-weight-medium">Estado de orden</span>
                                                            <span class="text-body-2">Actual</span>
                                                        </div>
                                                        <span style="font-size: 1.2rem !important;" class="text-subtitle-1 purple--text font-weight-medium">
                                                            {{order.order_state_name +(order.order_substate_name ? ' ('+order.order_substate_name+')' : '') }}
                                                        </span>
                                                    </v-card-text>
                                                </v-card>
                                            </div>
                                            <div class="col-sm-12 col-md-4 col-lg-2 col-xl-2">
                                                <v-card height="131px" outlined tile >
                                                    <v-card-text style="height: 100%" class="d-flex flex-column justify-space-between">
                                                        <div class="d-flex flex-column">
                                                            <span class="text-subtitle-1 font-weight-medium">Shipping number</span>
                                                            <span class="text-body-2">From courier</span>
                                                        </div>
                                                        <span style="font-size: 1.2rem !important;" class="text-subtitle-1 black--text font-weight-medium">
                                                            {{order.shipping_number ? order.shipping_number : '-'}}
                                                        </span>
                                                    </v-card-text>
                                                </v-card>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="kt-portlet">
                                    <div class="kt-portlet__body kt-portlet__body--fit">
                                        <div class="row row-no-padding row-col-separator-xl">
                                            <div class="col-md-2">
                                                <div class="kt-widget1" :style="{padding: '0.9rem', background: orderHistory.find(e => e.order_state && e.order_state.type == 'DEFAULT_IN_OMS') ? '#39C408':''}">
                                                    <div class="kt-widget1__item">
                                                        <div class="kt-widget1__info">
                                                            <h3 :class="['kt-widget1__title', orderHistory.find(e => e.order_state.type == 'DEFAULT_IN_OMS') ? 'kt-shape-font-color-1':'']">Ingresado</h3>
                                                            <span class="kt-widget1__desc kt-shape-font-color-1">{{orderHistory.find(e => e.order_state && e.order_state.type == 'DEFAULT_IN_OMS') ? $moment(orderHistory.find(e => e.order_state && e.order_state.type == 'DEFAULT_IN_OMS').created_at).format('DD-MM-YYYY HH:mm') :''}}</span>
                                                        </div>
                                                        <span :class="['kt-widget1__number', orderHistory.find(e => e.order_state && e.order_state.type == 'DEFAULT_IN_OMS') ? 'kt-shape-font-color-1':'']" style="font-size: 3em;"><i class="la la-check"></i></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-2">
                                                <div class="kt-widget1" :style="{padding: '0.9rem', background: orderHistory.find(e => e.order_state && e.order_state.type == 'DEFAULT_IN_PACKAGING') ? '#39C408':''}">
                                                    <div class="kt-widget1__item">
                                                        <div class="kt-widget1__info">
                                                            <h3 :class="['kt-widget1__title', orderHistory.find(e => e.order_state && e.order_state.type == 'DEFAULT_IN_PACKAGING') ? 'kt-shape-font-color-1':'']">Preparando</h3>
                                                            <span class="kt-widget1__desc  kt-shape-font-color-1">{{orderHistory.find(e => e.order_state && e.order_state.type == 'DEFAULT_IN_PACKAGING') ? $moment(orderHistory.find(e => e.order_state && e.order_state.type == 'DEFAULT_IN_PACKAGING').created_at).format('DD-MM-YYYY HH:mm') :''}}</span>
                                                        </div>
                                                        <span :class="['kt-widget1__number', orderHistory.find(e => e.order_state && e.order_state.type == 'DEFAULT_IN_PACKAGING') ? 'kt-shape-font-color-1':'']" style="font-size: 3em;"><i class="la la-dropbox"></i></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-2">
                                                <div class="kt-widget1" :style="{padding: '0.9rem', background: orderHistory.find(e => e.order_state && e.order_state.type == 'DEFAULT_OUT_SHIPMENT') ? '#39C408':''}">
                                                    <div class="kt-widget1__item">
                                                        <div class="kt-widget1__info">
                                                            <h3 :class="['kt-widget1__title', orderHistory.find(e => e.order_state && e.order_state.type == 'DEFAULT_OUT_SHIPMENT') ? 'kt-shape-font-color-1':'']">Listo para Enviar</h3>
                                                            <span class="kt-widget1__desc  kt-shape-font-color-1">{{orderHistory.find(e => e.order_state && e.order_state.type == 'DEFAULT_OUT_SHIPMENT') ? $moment(orderHistory.find(e => e.order_state && e.order_state.type == 'DEFAULT_OUT_SHIPMENT').created_at).format('DD-MM-YYYY HH:mm') :''}}</span>
                                                        </div>
                                                        <span :class="['kt-widget1__number', orderHistory.find(e => e.order_state && e.order_state.type == 'DEFAULT_OUT_SHIPMENT') ? 'kt-shape-font-color-1':'']" style="font-size: 3em;"><i class="la la-archive"></i></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-2">
                                                <div class="kt-widget1" :style="{padding: '0.9rem', background: orderHistory.find(e => e.order_state && e.order_state.type == 'DEFAULT_IN_CARRIER') ? '#39C408':''}">
                                                    <div class="kt-widget1__item">
                                                        <div class="kt-widget1__info">
                                                            <h3 :class="['kt-widget1__title', orderHistory.find(e => e.order_state && e.order_state.type == 'DEFAULT_IN_CARRIER') ? 'kt-shape-font-color-1':'']">Entregado a Transporte</h3>
                                                            <span class="kt-widget1__desc  kt-shape-font-color-1">{{orderHistory.find(e => e.order_state && e.order_state.type == 'DEFAULT_IN_CARRIER') ? $moment(orderHistory.find(e => e.order_state && e.order_state.type == 'DEFAULT_IN_CARRIER').created_at).format('DD-MM-YYYY HH:mm') :''}}</span>
                                                        </div>
                                                        <span :class="['kt-widget1__number', orderHistory.find(e => e.order_state && e.order_state.type == 'DEFAULT_IN_CARRIER') ? 'kt-shape-font-color-1':'']" style="font-size: 3em;"><i class="la la-exchange"></i></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-2">
                                                <div class="kt-widget1" :style="{padding: '0.9rem', background: orderHistory.find(e => e.order_state && e.order_state.type == 'DEFAULT_ON_CARRIER') ? '#39C408':''}">
                                                    <div class="kt-widget1__item">
                                                        <div class="kt-widget1__info">
                                                            <h3 :class="['kt-widget1__title', orderHistory.find(e => e.order_state && e.order_state.type == 'DEFAULT_ON_CARRIER') ? 'kt-shape-font-color-1':'']">En Ruta</h3>
                                                            <span class="kt-widget1__desc  kt-shape-font-color-1">{{orderHistory.find(e => e.order_state && e.order_state.type == 'DEFAULT_ON_CARRIER') ? $moment(orderHistory.find(e => e.order_state && e.order_state.type == 'DEFAULT_ON_CARRIER').created_at).format('DD-MM-YYYY HH:mm') :''}}</span>
                                                        </div>
                                                        <span :class="['kt-widget1__number', orderHistory.find(e => e.order_state && e.order_state.type == 'DEFAULT_ON_CARRIER') ? 'kt-shape-font-color-1':'']" style="font-size: 3em;"><i class="la la-truck"></i></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-2">
                                                <div class="kt-widget1" :style="{padding: '0.9rem', background: orderHistory.find(e => e.order_state && e.order_state.type == 'DEFAULT_IN_CUSTOMER') ? '#39C408':''}">
                                                    <div class="kt-widget1__item">
                                                        <div class="kt-widget1__info">
                                                            <h3 :class="['kt-widget1__title', orderHistory.find(e => e.order_state && e.order_state.type == 'DEFAULT_IN_CUSTOMER') ? 'kt-shape-font-color-1':'']">Entregado a cliente</h3>
                                                            <span class="kt-widget1__desc  kt-shape-font-color-1">{{orderHistory.find(e => e.order_state && e.order_state.type == 'DEFAULT_IN_CUSTOMER') ? $moment(orderHistory.find(e => e.order_state && e.order_state.type == 'DEFAULT_IN_CUSTOMER').created_at).format('DD-MM-YYYY HH:mm') :''}}</span>
                                                        </div>
                                                        <span :class="['kt-widget1__number', orderHistory.find(e => e.order_state && e.order_state.type == 'DEFAULT_IN_CUSTOMER') ? 'kt-shape-font-color-1':'']" style="font-size: 3em;"><i class="la la-flag-checkered"></i></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <b-tabs content-class="mt-3" fill>
                                    <b-tab title="Productos" active>
                                        <div>
                                            <v-card v-if="order.current_state_detail.type == 'DEFAULT_ON_HOLD' && editOrderDetailPermission">
                                                <v-card-text>

                                                    <v-btn @click="editarProductos({
                                                        order_id: orden_id, 
                                                        store_id: order.store_id, 
                                                        total_products: order.total_products, 
                                                        total_shipping: order.total_shipping,
                                                        total_discount: order.total_discount,
                                                        total_paid: order.total_paid,
                                                        items: order.items
                                                    })" color="default">
                                                        <v-icon left>mdi-pencil</v-icon> Editar Productos
                                                    </v-btn>
                                                    
                                                </v-card-text>
                                            </v-card>
                                        </div>
                                        <v-simple-table fixed-header height="300px" class="elevation-1 datatable">
                                            <template v-slot:default>
                                                <thead>
                                                    <tr>
                                                        <th class="text-left">ID</th>
                                                        <th class="text-left">Nombre</th>
                                                        <th class="text-left">SKU</th>
                                                        <th class="text-left">EAN</th>
                                                        <th class="text-left">Cantidad</th>
                                                        <th class="text-left">Total</th>
                                                        <!-- <th class="text-left">Fecha ingreso al sistema</th> -->
                                                        <th class="text-left">Bodega</th>
                                                        <th class="text-left" v-if="order.current_state_detail.type == 'DEFAULT_ON_HOLD'">Stock Disponible</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(item,index) in orderDetail" :key="index">
                                                        <td>{{ item.id }}</td>
                                                        <td>
                                                            <v-tooltip top>
                                                                <template v-slot:activator="{ on }">
                                                                    <v-btn @click="queryStock(item.sku)" text color="primary" dark v-on="on">{{item.product}}</v-btn>
                                                                </template>
                                                                <span>Consultar stock</span>
                                                            </v-tooltip>
                                                        </td>
                                                        <td>{{ item.sku }}</td>
                                                        <td>{{ item.ean }}</td>
                                                        <td>{{ item.quantity }}</td>
                                                        <td>{{ item.total }}</td>
                                                        <!-- <td>{{ $moment(item.created_at).format('DD-MM-YYYY HH:mm') }}</td> -->
                                                        <td>{{ item.warehouse }}</td>
                                                        <td v-if="order.current_state_detail.type == 'DEFAULT_ON_HOLD'" :style="[orderDetailStock[item.sku].quantity < 0 ? {'background-color': '#fff3c1'} : orderDetailStock[item.sku].quantity == '-' ? {'background-color': '#ffd3d3'} : {} ]" >
                                                            <PuSkeletonTheme color="#cdd4d7" highlight="#ffffff">
                                                                <PuSkeleton :count="1" width="100%" height="12px">{{orderDetailStock[item.sku].quantity}}</PuSkeleton>
                                                            </PuSkeletonTheme>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </template>
                                        </v-simple-table>
                                    </b-tab>
                                    <b-tab title="Cliente">
                                        <v-list two-line subheader>
                                            <v-subheader style="font-size: 1.2rem;"><v-badge style="cursor: pointer;" icon="mdi mdi-pencil" @click.native="editarCliente" > Datos Cliente</v-badge></v-subheader>
                                            <v-list-item>
                                                <v-list-item-content>
                                                    <v-list-item-title>Nombre</v-list-item-title>
                                                    <v-list-item-subtitle>{{customer.fullname}}</v-list-item-subtitle>
                                                    <v-list-item-title>Email</v-list-item-title>
                                                    <v-list-item-subtitle>{{customer.email}}</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-subheader
                                                style="font-size: 1.2rem;" v-if="order.additional_data != null && order.additional_data.is_company == '1' && store_country == 'CL'"
                                            >Datos Empresa</v-subheader>
                                            <v-list-item v-if="order.additional_data != null && order.additional_data.is_company == '1'  && store_country == 'CL' ">
                                                <v-list-item-content>
                                                    <v-list-item-title>Nombre</v-list-item-title>
                                                    <v-list-item-subtitle>{{(order.additional_data != null && order.additional_data.company_name ? order.additional_data.company_name : '')}}</v-list-item-subtitle>

                                                    <v-list-item-title>Giro</v-list-item-title>
                                                    <v-list-item-subtitle>{{(order.additional_data != null && order.additional_data.company_activity ? order.additional_data.company_activity : '')}}</v-list-item-subtitle>

                                                    <v-list-item-title>RUT</v-list-item-title>
                                                    <v-list-item-subtitle>{{(order.additional_data != null && order.additional_data.company_identification ? order.additional_data.company_identification : '')}}</v-list-item-subtitle>

                                                    <v-list-item-title>Dirección</v-list-item-title>
                                                    <v-list-item-subtitle>{{(order.additional_data != null && order.additional_data.company_address ? order.additional_data.company_address : '')}}</v-list-item-subtitle>

                                                    <v-list-item-title>Región</v-list-item-title>
                                                    <v-list-item-subtitle>{{(order.additional_data != null && order.additional_data.company_state ? order.additional_data.company_state : '')}}</v-list-item-subtitle>

                                                    <v-list-item-title>Comuna</v-list-item-title>
                                                    <v-list-item-subtitle>{{(order.additional_data != null && order.additional_data.company_province ? order.additional_data.company_province : '')}}</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-subheader  style="font-size: 1.2rem;">Metadatos</v-subheader>
                                            <v-list-item>
                                                <v-list-item-content>
                                                    <v-list-item-title>Fecha creación</v-list-item-title>
                                                    <v-list-item-subtitle>{{customer.created_at}}</v-list-item-subtitle>
                                                    <v-list-item-title>Store</v-list-item-title>
                                                    <v-list-item-subtitle>{{customer.store.name}}</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list>
                                    </b-tab>
                                    <b-tab title="Dirección">
                                        <v-list two-line subheader>
                                            <v-subheader style="font-size: 1.2rem;"><v-badge style="cursor: pointer;" icon="mdi mdi-pencil" @click.native="editarDireccion" > Datos Dirección</v-badge></v-subheader>
                                            <v-list-item>
                                                <v-list-item-content>
                                                    <v-list-item-title>ID</v-list-item-title>
                                                    <v-list-item-subtitle>{{address.id}}</v-list-item-subtitle>
                                                    <v-list-item-title>Calle</v-list-item-title>
                                                    <v-list-item-subtitle>{{address.street}}</v-list-item-subtitle>
                                                    <v-list-item-title>Número</v-list-item-title>
                                                    <v-list-item-subtitle>{{address.number}}</v-list-item-subtitle>
                                                    <v-list-item-title>Dpto</v-list-item-title>
                                                    <v-list-item-subtitle>{{address.dpto}}</v-list-item-subtitle>
                                                    <v-list-item-title>Ciudad</v-list-item-title>
                                                    <v-list-item-subtitle>{{address.city}}</v-list-item-subtitle>
                                                    <v-list-item-title>Provincia</v-list-item-title>
                                                    <v-list-item-subtitle>{{address.province}}</v-list-item-subtitle>
                                                    <v-list-item-title>Región</v-list-item-title>
                                                    <v-list-item-subtitle>{{address.state}}</v-list-item-subtitle>
                                                    <v-list-item-title>Código Postal</v-list-item-title>
                                                    <v-list-item-subtitle>{{address.zip_code}}</v-list-item-subtitle>
                                                    <v-list-item-title>DNI</v-list-item-title>
                                                    <v-list-item-subtitle>{{address.dni}}</v-list-item-subtitle>
                                                    <v-list-item-title>Teléfono</v-list-item-title>
                                                    <v-list-item-subtitle>{{address.phone}}</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                            <v-subheader  style="font-size: 1.2rem;">Datos Relacionados</v-subheader>
                                            <v-list-item>
                                                <v-list-item-content>
                                                    <v-list-item-title>Cliente</v-list-item-title>
                                                    <v-list-item-subtitle>{{address.customer.fullname}} (ID: {{address.customer.id}})</v-list-item-subtitle>
                                                    <v-list-item-title>Store</v-list-item-title>
                                                    <v-list-item-subtitle>{{address.store.name}} (ID: {{address.store.id}})</v-list-item-subtitle>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list>
                                    </b-tab>
                                    <b-tab title="Acciones">
                                        <div class="row">
                                            <div class="col-md-6" v-if="!['DEFAULT_CANCELED','DEFAULT_IN_CUSTOMER','DEFAULT_REDELIVERY'].includes(order.current_state_detail.type) || editOrderDetailPermission">
                                                <v-card>
                                                    <v-card-text style=" position: relative">
                                                        <v-btn
                                                            
                                                            @click="cambiarEstado({order_id: orden_id, store_id: order.store_id, current_state: order.current_state, current_substate_name: order.current_sub_state})"
                                                        >
                                                            <v-icon left>mdi-content-save</v-icon>
                                                            Cambiar estado
                                                        </v-btn>
                                                    </v-card-text>
                                                </v-card>
                                            </div>

                                            <div class="col-md-6" v-if="order.current_state_detail.order < 6 ">
                                                <v-card>
                                                    <v-card-text  class="grey lighten-5">
                                                        <v-select
                                                            v-model="order_carrier"
                                                            :items="carrierList"
                                                            label="Cambiar Carrier"
                                                            required
                                                        ></v-select>
                                                    </v-card-text>
                                                    <v-card-text style=" position: relative">
                                                        <v-btn
                                                            absolute
                                                            dark
                                                            fab
                                                            top
                                                            right
                                                            color="pink"
                                                            @click="sendFormCarrier"
                                                        >
                                                            <v-icon>mdi-content-save</v-icon>
                                                        </v-btn>
                                                    </v-card-text>
                                                </v-card>
                                                        
                                            </div>

                                            
                                        </div>
                                    </b-tab>
                                    <b-tab title="Registros"  v-if="shipmentLogData && shipmentLogData.length > 0">
                                        <v-simple-table fixed-header class="elevation-1 datatable">
                                            <template v-slot:default>
                                                <thead>
                                                    <tr>
                                                        <th class="text-left">Fecha consulta</th>
                                                        <th class="text-left">Courier</th>
                                                        <th class="text-left">Request</th>
                                                        <th class="text-left">Response</th>
                                                        <th class="text-left">Mensaje</th>
                                                        
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(item,index) in shipmentLogData" :key="index">
                                                        <td>{{ $moment(item.created_at).format('DD-MM-YYYY HH:mm') }}</td>
                                                        <td>{{ shipmentLogCarrier }}</td>
                                                        <td>
                                                            <v-btn
                                                            color="blue lighten-2"
                                                            @click="openShipmentLogRequest({requestShipmentLog: item.request})">
                                                            Ver Request
                                                            </v-btn>
                                                        </td>
                                                        <td>
                                                            <v-btn
                                                            color="blue lighten-2"
                                                            @click="openShipmentLogResponse({responseShipmentLog: item.response})">
                                                            Ver Response
                                                            </v-btn>
                                                        </td>
                                                        <td>{{ item.message }}</td>
                                                        
                                                    </tr>
                                                </tbody>
                                            </template>
                                        </v-simple-table>
                                    </b-tab>
                                </b-tabs>
                                        
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                        <v-card
                                            color="#385F73"
                                            dark
                                            min-width="250px"
                                        >
                                            <v-card-title class="headline">Shipping number</v-card-title>
                                            <v-card-subtitle>{{order.shipping_number}}</v-card-subtitle>
                                        </v-card>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                        <v-card
                                            color="#385F73"
                                            dark
                                            min-width="250px"
                                        >
                                            <v-card-title class="headline">Total productos</v-card-title>
                                            <v-card-subtitle>{{order.total_products}}</v-card-subtitle>
                                        </v-card>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                        <v-card
                                            color="#385F73"
                                            dark
                                            min-width="250px"
                                        >
                                            <v-card-title class="headline">Carrier</v-card-title>
                                            <v-card-subtitle>{{order.carrier}}</v-card-subtitle>
                                        </v-card>
                                    </div>
                                    
                                </div>
                                <div class="row">
                                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                        <v-card
                                            color="#385F73"
                                            dark
                                            min-width="250px"
                                        >
                                            <v-card-title class="headline">Total descuentos</v-card-title>
                                            <v-card-subtitle>{{order.total_discount}}</v-card-subtitle>
                                        </v-card>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                        <v-card
                                            color="#385F73"
                                            dark
                                            min-width="250px"
                                        >
                                            <v-card-title class="headline">Total pagado</v-card-title>
                                            <v-card-subtitle>{{order.total_paid}}</v-card-subtitle>
                                        </v-card>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                        <v-card
                                            color="#385F73"
                                            dark
                                            min-width="250px"
                                        >
                                            <v-card-title class="headline">Total Shipping</v-card-title>
                                            <v-card-subtitle>{{order.total_shipping}}</v-card-subtitle>
                                        </v-card>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                        <v-card
                                            color="#385F73"
                                            dark
                                            min-width="250px"
                                        >
                                            <v-card-title class="headline">Fecha desde Marketplace</v-card-title>
                                            <v-card-subtitle>{{$moment(order.source_date).format('DD-MM-YYYY HH:mm')}}</v-card-subtitle>
                                        </v-card>
                                    </div>
                                    
                                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                        <v-card
                                            color="#385F73"
                                            dark
                                            min-width="250px"
                                        >
                                            <v-card-title class="headline">WMS ID</v-card-title>
                                            <v-card-subtitle>
                                                 <PuSkeletonTheme color="#4d8bab" highlight="#589fc3">
                                                    <PuSkeleton :count="1" width="100%" height="12px">{{ order_logistic_number }}</PuSkeleton>
                                                </PuSkeletonTheme>
                                            </v-card-subtitle>
                                        
                                        </v-card>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                        <v-card color="#385F73" dark min-width="250px" >
                                            <v-card-title class="headline">Forma de Ingreso</v-card-title>
                                            <v-card-subtitle>{{order.input.name}}</v-card-subtitle>
                                        </v-card>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4"  v-if="order.redelivery_from">
                                        <v-card
                                            light
                                            min-width="250px"
                                        >
                                            <v-card-title class="headline">Redespacho desde:</v-card-title>
                                            <v-card-subtitle><a class="text-decoration-none" href="#" @click="$router.push({name: 'orden', params: {orden: order.redelivery_from}})">{{order.redelivery_from}}</a></v-card-subtitle>
                                        </v-card>
                                    </div>
                                    
                                    <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4" v-if="order_redelivery_to != null">
                                        <v-card
                                            light
                                            min-width="250px"
                                        >
                                            <v-card-title class="headline">Redespachado en: </v-card-title>
                                            <v-card-subtitle>
                                                 <PuSkeletonTheme color="#e2e0e0" highlight="#ffffff">
                                                    <PuSkeleton :count="1" width="100%" height="12px">
                                                        <div v-for="(val, index) in order_redelivery_to" :key="index">
                                                        <a class="text-decoration-none" href="#" @click="$router.push({name: 'orden', params: {orden: val}})">{{val}}</a>
                                                        </div>
                                                    </PuSkeleton>
                                                </PuSkeletonTheme>
                                            </v-card-subtitle>
                                            
                                        </v-card>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <v-expansion-panels >
                            <v-expansion-panel v-if="additionalData">
                                 <v-expansion-panel-header>
                                    <h6 class="card-title">Información adicional</h6>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-container style="max-width: 100%;">
                                        <v-simple-table fixed-header height="300px" class="elevation-2 datatable">
                                            <template v-slot:default>
                                                <thead>
                                                <tr>
                                                    <th class="text-left">Dato</th>
                                                    <th class="text-left">Valor</th>
                                                </tr>
                                                </thead>
                                                <tbody>

                                                <tr v-for="(ad,index) in additionalData" :key="index">
                                                    <td>{{ index }}</td>
                                                    <td>
                                                      {{ ad }}
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </template>
                                        </v-simple-table>
                                    </v-container>
                                </v-expansion-panel-content>
                            </v-expansion-panel>


                            <v-expansion-panel v-if="additionalDataPod">
                                 <v-expansion-panel-header>
                                    <h6 class="card-title">Información de Entrega</h6>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-container style="max-width: 100%;">
                                        <v-simple-table fixed-header height="300px" class="elevation-2 datatable">
                                            <template v-slot:default>
                                                <thead>
                                                <tr>
                                                    <th class="text-left">Dato</th>
                                                    <th class="text-left">Valor</th>
                                                </tr>
                                                </thead>
                                                <tbody>

                                                <tr v-for="(ad,index) in additionalDataPod" :key="index">
                                                    <td>{{ index }}</td>
                                                    <td v-if="isValidUrl(ad)">
                                                       <a :href="ad" target="_blank">Imagen</a>
                                                    </td>
                                                    <td v-else>
                                                      {{ ad }}
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </template>
                                        </v-simple-table>
                                    </v-container>
                                </v-expansion-panel-content>
                            </v-expansion-panel>

                            <v-expansion-panel v-if="orderHistory.length">
                                <v-expansion-panel-header>
                                    
                                    <h6 class="card-title">Bitácora de estados</h6>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <!-- <div>
                                    <b-card title="" sub-title="">
                                                                        <b-form-textarea
                                            id="textarea"
                                            v-model="comment"
                                            placeholder="Ingresar Comnentario"
                                            rows="3"
                                            max-rows="3"
                                        ></b-form-textarea>

             
        
                                    <v-btn class="ma-2" @click="saveComment()" :disabled="!comment.length" > <v-icon left>mdi-comment</v-icon>Guardar comentario</v-btn>
                                    </b-card>
                                    </div> -->

                                    <v-container style="max-width: 600px;">
                                        <v-timeline dense clipped reverse>
                                            <v-timeline-item
                                            fill-dot
                                            class="white--text mb-12"
                                            color="orange"
                                            large
                                            >
                                            <template v-slot:icon>
                                                <span>Usuario</span>
                                            </template>
                                            <v-text-field
                                                v-model="comment"
                                                hide-details
                                                flat
                                                label="Ingresar Comentario..."
                                                solo
                                                @keydown.enter="saveComment()"
                                            >
                                                <template v-slot:append>
                                                <v-btn
                                                    class="mx-0 primary"
                                                    @click="saveComment()"
                                                >
                                                    Guardar Comentario
                                                </v-btn>
                                                </template>
                                            </v-text-field>
                                            </v-timeline-item>
                                            
                                            <v-timeline-item
                                                class="mb-4" small :color="oh.comment ? 'orange lighten-1' : (!oh.order_state ? 'info' : (oh.order_state.colour == 'danger' ? 'red' : (oh.order_state.colour == 'outline-info' ? 'info' : (oh.order_state.colour == 'dark' ? 'blue darken-4' : oh.order_state.colour))))"
                                                v-for="(oh,index) in orderHistory"
                                                :key="index"
                                            >
                                                <v-row justify="space-between">
                                                    <v-col cols="9" v-if="!oh.comment" >
                                                        <v-card class="mx-auto" max-width="344" >
                                                            <v-card-text>
                                                                <div class="text--primary"> Actualizado a estado: {{oh.order_state.name}} </div>
                                                                <v-list dense v-if="oh.agent">
                                                                    <v-subheader>
                                                                        <v-chip class="white--text ml-0" color="purple" label small v-if="oh.agent" >
                                                                            {{oh.agent.type.toUpperCase()}}
                                                                        </v-chip>
                                                                    </v-subheader>
                                                                    <v-list-item-group>
                                                                        <v-list-item v-for="(atributo, i) in Object.keys(oh.agent.data)" :key="i" >
                                                                            <v-list-item-content>
                                                                                <v-list-item-title ><b>{{atributo}}</b>: {{oh.agent.data[atributo]}}</v-list-item-title>
                                                                            </v-list-item-content>
                                                                        </v-list-item>
                                                                    </v-list-item-group>
                                                                </v-list>
                                                            </v-card-text>
                                                        </v-card>
                                                    </v-col>
                                                    <v-col cols="9" v-if="oh.comment" >
                                                        <v-card class="mx-auto" max-width="344" >
                                                            <v-card-text>
                                                                <div class="text--primary"> <b>Comentario:</b> {{oh.comment}} </div>
                                                                <v-list dense>
                                                                    <v-list-item-group>
                                                                        <v-list-item>
                                                                            <v-list-item-content><v-list-item-title ><b>Usuario</b>: {{oh.user_name}}</v-list-item-title></v-list-item-content>
                                                                        </v-list-item>
                                                                        <v-list-item>
                                                                            <v-list-item-content><v-list-item-title ><b>Email</b>: {{oh.user_email}}</v-list-item-title></v-list-item-content>
                                                                        </v-list-item>
                                                                    </v-list-item-group>
                                                                </v-list>
                                                            </v-card-text>
                                                        </v-card>
                                                    </v-col>
                                                    <v-col class="text-right" cols="3" > {{$moment(oh.created_at).format("DD-MM-YYYY HH:mm")}} </v-col>
                                                </v-row>
                                            </v-timeline-item>
                                        </v-timeline>
                                    </v-container>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-card-text>
                </v-card>
            </div>
        </div>
        <v-dialog v-model="showDialogEditarDatosCliente" max-width="1000px">
            <v-card>
                <v-card-title>
                    <span>Editar Cliente #{{customer.id}}</span>
                </v-card-title>
                <v-card-text>
                    <v-form ref="form" lazy-validation >
                        <v-text-field v-model="customer.firstname" :counter="200" label="Nombre" required ></v-text-field>
                        <v-text-field v-model="customer.lastname" :counter="200" label="Apellidos" required ></v-text-field>
                        <v-text-field v-model="customer.email" :rules="emailRules" label="E-mail" required ></v-text-field>
                        <div  v-if="store_country == 'CL'">
                            <v-checkbox   v-model="order.additional_data.is_company" label="Es empresa?" > Es empresa? </v-checkbox>
                            <v-text-field v-model="order.additional_data.company_name" label="Nombre Empresa" v-if="order.additional_data.is_company"></v-text-field>
                            <v-text-field v-model="order.additional_data.company_activity" label="Giro Empresa" v-if="order.additional_data.is_company"></v-text-field>
                            <v-text-field v-model="order.additional_data.company_identification" label="RUT Empresa" v-if="order.additional_data.is_company"></v-text-field>
                            <v-text-field v-model="order.additional_data.company_address" label="Dirección Empresa" v-if="order.additional_data.is_company"></v-text-field>
                            <v-text-field v-model="order.additional_data.company_state" label="Región Empresa" v-if="order.additional_data.is_company"></v-text-field>
                            <v-text-field v-model="order.additional_data.company_province" label="Comuna Empresa" v-if="order.additional_data.is_company"></v-text-field>
                        </div>
                        
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="primary" text @click="showDialogEditarDatosCliente = false" > Cancelar </v-btn>
                    <v-btn color="success" text @click="sendFormDatosCliente" > Guardar </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="showDialogEditarDatosDireccion" max-width="1000px">
            <v-card>
                <v-card-title>
                    <span>Editar Dirección #{{address.id}}</span>
                </v-card-title>
                <v-card-text>
                    <v-form
                    ref="form"
                    
                    lazy-validation
                    >
                        <v-text-field
                            v-model="address.street"
                            :rules="defaultRequiredRules"
                            :counter="200"
                            label="Calle"
                            required
                        ></v-text-field>
                        <v-text-field
                            v-model="address.number"
                            :rules="defaultRequiredRules"
                            :counter="200"
                            label="Número"
                            required
                        ></v-text-field>
                        <v-text-field
                            v-model="address.dpto"
                            :rules="defaultRequiredRules"
                            label="Dpto"
                            required
                        ></v-text-field>
                        <v-text-field
                            v-model="address.city"
                            :rules="defaultRequiredRules"
                            label="Ciudad"
                            required
                        ></v-text-field>
                        <v-text-field
                            v-model="address.province"
                            :rules="defaultRequiredRules"
                            label="Provincia"
                            required
                        ></v-text-field>
                        <v-text-field
                            v-model="address.state"
                            :rules="defaultRequiredRules"
                            label="Región"
                            required
                        ></v-text-field>
                        <v-text-field v-model="address.zip_code" label="Código postal" ></v-text-field>
                        <v-text-field
                            v-model="address.dni"
                            :rules="defaultRequiredRules"
                            label="DNI"
                            required
                        ></v-text-field>
                        <v-text-field
                            v-model="address.phone"
                            :rules="defaultRequiredRules"
                            label="Teléfono"
                            required
                        ></v-text-field>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        color="primary"
                        text
                        @click="showDialogEditarDatosDireccion = false"
                        >
                        Cancelar
                    </v-btn>
                    <v-btn
                        color="success"
                        text
                        @click="sendFormDatosDireccion"
                        >
                        Guardar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-snackbar
            v-model="snackbarStock"
            :timeout="-1"
            >
            <span v-html="snackbarStockQuantity"></span>
           

            <template v-slot:action="{ attrs }">
                <!-- <v-btn
                color="blue"
                text
                v-bind="attrs"
                @click="copyToClipboard(snackbarStockQuantity)"
                >
                    <v-icon left>
                        mdi-content-copy
                    </v-icon>
                    Copiar
                </v-btn> -->
                <v-btn
                color="green"
                rounded
                v-bind="attrs"
                @click="snackbarStock = false"
                >
                Ok
                </v-btn>
            </template>
        </v-snackbar>
        <div class="container" v-show="false">
            <input type="text" v-model="contentForCopy">
        </div>
    </v-app>
    <dialog-cambiar-estado-orden @success="fetchData"></dialog-cambiar-estado-orden>
    <dialog-shipment-log-request></dialog-shipment-log-request>
    <dialog-shipment-log-response></dialog-shipment-log-response>
    <dialog-editar-productos-orden @success="fetchData"></dialog-editar-productos-orden>
</div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import DialogCambiarEstadoOrden from "@/views/oms/Ordenes/DialogCambiarEstadoOrden";
import DialogShipmentLogRequest from "@/views/oms/Ordenes/DialogShipmentLogRequest";
import DialogShipmentLogResponse from "@/views/oms/Ordenes/DialogShipmentLogResponse";
import DialogEditarProductosOrden from "@/views/oms/Ordenes/DialogEditarProductosOrden";
export default {
    components: {
        DialogCambiarEstadoOrden,
        DialogShipmentLogRequest,
        DialogShipmentLogResponse,
        DialogEditarProductosOrden,
    },
    data(){
        return{
            showDialogEditarDatosCliente: false,
            showDialogEditarDatosDireccion: false,
            orden_id: undefined,
            order: {
                address:null,
                address_id:null,
                courier_tracking_id:null,
                created_at:null,
                current_state:null,
                customer_id:null,
                id:null,
                order_reference:null,
                order_state_name:null,
                process_order:null,
                province:null,
                shipping_number:null,
                source:null,
                source_date:null,
                source_id:null,
                state:null,
                store_id:null,
                total_discount:null,
                total_paid:null,
                total_products:null,
                total_shipping:null,
                total_order: null,
                updated_at: null,
                warehouse_tracking_id: null,
                order_carrier: null,
                current_state_detail: {
                    id: null,
                    name: null,
                    store_id: null,
                    type: null,
                    order: null,
                    colour: null
                },
                input:{
                    id: null,
                    name: null,
                },
                additional_data: {
                    is_company: false,
                    company_name: undefined,
                    company_activity: undefined,
                    company_identification: undefined,
                    company_address: undefined,
                    company_state: undefined,
                    company_province: undefined,
                },
                items: [],
            },
            customer: {
                created_at:null,
                email:null,
                firstname:null,
                fullname:null,
                id:null,
                lastname:null,
                store:{},
                store_id:null,
                updated_at:null,
            },
            address: {
                city:null,
                created_at:null,
                customer:{},
                customer_id:null,
                dni:null,
                dpto:null,
                id:null,
                number:null,
                phone:null,
                province:null,
                state:null,
                zip_code:null,
                store:{},
                store_id:null,
                street:null,
                updated_at:null,
            },
            source: {
                id:null,
                name:null,
                type_id:null
            },
            orderHistory: [],
            histories: undefined,
            orderDetail: undefined,
            shipmentLogData: {},
            shipmentLogOR: undefined,
            shipmentLogCarrier: undefined,
            statusAll: undefined,
            additionalData: undefined,
            emailRules: [
                value => !!value || 'Requerido.',
                value => (value || '').length <= 200 || 'Máximo 200 caracteres',
                value => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                return pattern.test(value) || 'Email inválido.'
                },
            ],
            defaultRequiredRules: [
                v => !!v || 'Este campo es obligatorio',
            ],
            current_state: undefined,
            order_carrier: undefined,
            orderStatesList: [],
            carrierList: [],
            snackbarStock: false,
            snackbarStockQuantity: 0,
            contentForCopy: '',
            dialogShipmentLogRequest: false,
            dialogShipmentLogResponse: false,
            comment: "",
            store_country : 'CL',
            order_logistic_number: null,
            order_redelivery_to: null,
            orderDetailStock: {},
            orderDetailStock2: {},
            additionalDataPod: false,
            editOrderDetailPermission: this.$store.getters['passport/userCan']({ "name": "Modificacion ordenes", "category": "Módulo Órdenes" })
        }
    },
    methods: {
        saveComment(){            
            var vm = this;
            this.axios({
                url: 'orders/comment/'+vm.order.id,
                method: 'POST',
                data: { comment: vm.comment }
            }).then( (response) => {
                vm.$bvToast.toast(response.data, {
                    title: `Información`, variant: 'success', solid: true, toaster: 'b-toaster-bottom-center'
                });
                vm.fetchData();
                vm.comment = "";
            }).catch( error => {
                console.log(error);
                vm.comment = "";
            });
        },
        fetchData(){
            var vm = this;
            this.axios({
                //url: 'http://oldoms.testing.fullkom.com/api/orders/'+this.$route.params.orden
                url: 'orders/'+this.$route.params.orden
            }).then( response => {

                if (response.data.order.additional_data != null){
                    vm.additionalData = JSON.parse(response.data.order.additional_data);
                }

                if (response.data.order.additional_data != null && response.data.order.additional_data.is_company == '1') {
                    response.data.order.additional_data.is_company =  true;
                }else{
                    response.data.order.additional_data = JSON.parse('{"is_company":false, "company_name":null, "company_activity":null, "company_identification":null, "company_address":null, "company_state":null, "company_province":null}');
                }

                vm.orden_id = response.data.order.id;
                vm.order = response.data.order;
                vm.customer = response.data.customer;
                vm.address = response.data.address;
                vm.source = response.data.source;
                vm.orderHistory = response.data.orderHistory;
                vm.histories = response.data.histories;
                vm.orderDetail = response.data.orderDetail;
                vm.statusAll = response.data.statusAll;
                vm.current_state = response.data.order.current_state;
                vm.order_carrier = response.data.order.carrier;
                vm.current_state_detail = response.data.order.current_state_detail;

                vm.orderDetail.forEach( element => {
                    vm.orderDetailStock[element.sku] = {
                        quantity: null,
                        physical_quantity: null,
                        reserved_quantity: null
                    }
                });

                vm.order.items = [];
                vm.orderDetail.forEach( o => {
                    vm.order.items.push({
                        sku: o.sku,
                        ean: o.ean,
                        quantity: o.quantity,
                        name: o.product,
                        price: o.total,
                    })
                });
                
                vm.fetchOrderStates();
                vm.fetchCouriers();
                vm.getShipmentLog();
                vm.getLogisticNumber();
                vm.gerOrdesRelatedTo();
                if(vm.order.current_state_detail.type == 'DEFAULT_ON_HOLD') {
                    vm.gerOrdesDetailStock();
                }
                
                vm.gerOrderPod();
            }).catch( error => {
                console.log(error);
                //vm.$router.push({name: '404'});
                vm.$router.push("/ordenes");
            });
        },
        queryStock(sku)
        {
            var vm = this;
            this.axios({
                url: 'order/products/'+sku+'/query_stock'
            }).then( response => {
                let snackbarStockQuantityString = '<table class="table" style="color:#fff"><thead><tr><th>Bodega</th><th style="text-align: center;">Stock Disponible</th></tr></thead><tbody>';
                Object.keys(response.data).forEach( element => {
                    

                        snackbarStockQuantityString += '<tr><td>'+response.data[element]['warehouse']+ '</td><td style="text-align: center;"><strong>'+response.data[element]['quantity']+'</strong></td></tr>';
                });
                snackbarStockQuantityString += '</tbody></table>';
                vm.snackbarStockQuantity = snackbarStockQuantityString;    
            }).catch( error => {
                vm.snackbarStockQuantity = 0;
            }).finally( () => {
                vm.snackbarStock = true;
            });
        },
        getShipmentLog(){
             var vm = this;
            this.axios({
                url: 'shipment/shipmentlogs/order_id/'+this.$route.params.orden
            }).then( response => {
                console.log(response.data);
                vm.shipmentLogData = response.data.data;
                vm.shipmentLogOR = response.data.order_reference;
                vm.shipmentLogCarrier = response.data.carrier;
            }).catch( error => {
                
            });
        },
        getLogisticNumber(){
             var vm = this;
            this.axios({
                url: 'orders/logistic_number/'+this.$route.params.orden
            }).then( response => {
                console.log(response.data);
                vm.order_logistic_number = response.data;
                console.log( vm.order_logistic_number);
            }).catch( error => {
                
            });
        },
        gerOrdesRelatedTo(){
             var vm = this;
            this.axios({
                url: 'orders/redelivery_to/'+this.$route.params.orden
            }).then( response => {
                console.log(response.data);
                vm.order_redelivery_to = response.data.redelivery_to;
                console.log( vm.order_redelivery_to);
            }).catch( error => {
                
            });
        },
        gerOrdesDetailStock(){
             var vm = this;
            this.axios({
                url: '/order/orderdetail/'+this.$route.params.orden+'/query_stock'
            }).then( response => {
                var data = response.data;
                var orderDetailStock_array = [];
                vm.orderDetail.forEach( element => {
                    if (data[element.sku]) {
                        orderDetailStock_array[element.sku] = {
                            quantity: data[element.sku].quantity,
                            physical_quantity: data[element.sku].physical_quantity,
                            reserved_quantity: data[element.sku].reserved_quantity
                        }
                    }
                    else{
                        orderDetailStock_array[element.sku] = {
                            quantity: '-',
                            physical_quantity: '-',
                            reserved_quantity: '-'
                        }
                    }
                    
                });
               // vm.orderDetailStock = orderDetailStock_array;

               vm.orderDetail.forEach( element => {
                 
                    vm.orderDetailStock2[element.sku] = {
                        quantity: orderDetailStock_array[element.sku].quantity,
                        physical_quantity: orderDetailStock_array[element.sku].physical_quantity,
                        reserved_quantity: orderDetailStock_array[element.sku].reserved_quantity
                    }
                });
                vm.orderDetailStock = vm.orderDetailStock2;

             
            }).catch( error => {
                
            });
        },
        gerOrderPod(){
            if(this.order.current_state_detail.type == 'DEFAULT_IN_CUSTOMER') {
                 var vm = this;
                this.axios({
                    url: 'shipment/pod/'+this.$route.params.orden
                }).then( response => {
                    console.log(response.data);
                    vm.additionalDataPod = response.data.pod;
                    console.log( vm.additionalDataPod);
                }).catch( error => {
                    
                });
            }

        },
        copyToClipboard(content)
        {
            this.contentForCopy = String(content);
            this.$copyText(this.contentForCopy);
        },
        editarCliente()
        {
            this.showDialogEditarDatosCliente = true;
        },
        editarDireccion()
        {
            this.showDialogEditarDatosDireccion = true;
        },
        sendFormDatosCliente(){
            console.log('Actualizando datos cliente');
            
            var vm = this;
            this.axios({
                url: 'orders/customer/'+this.customer.id,
                method: 'POST',
                data: {
                    firstname: this.customer.firstname,
                    lastname: this.customer.lastname,
                    email: this.customer.email,
                    order_id: this.$route.params.orden,
                    additional_data: this.order.additional_data
                }
            }).then( (response) => {
                vm.$bvToast.toast(response.data, {
                    title: `Información`,
                    variant: 'success',
                    solid: true,
                    toaster: 'b-toaster-bottom-center'
                });
                vm.fetchData();
                vm.showDialogEditarDatosCliente = false;
            }).catch( error => {
                console.log(error);
                vm.showDialogEditarDatosCliente = false;
            });
        },
        sendFormDatosDireccion(){
            console.log('actualiazndo datos direccion');
            var vm = this;
            this.axios({
                url: 'orders/address/'+this.address.id,
                method: 'POST',
                data: this.address
            }).then( (response) => {
                vm.$bvToast.toast(response.data, {
                    title: `Información`,
                    variant: 'success',
                    solid: true,
                    toaster: 'b-toaster-bottom-center'
                });
                vm.fetchData();
                vm.showDialogEditarDatosDireccion = false;
            }).catch( error => {
                console.log(error);
                vm.showDialogEditarDatosDireccion = false;
            });
        },
        fetchOrderStates(){
            var vm = this;
            this.axios({
                url: 'orders/states/data_select_input?store='+this.order.store_id
            }).then( response => {
                vm.orderStatesList = response.data;
            }).catch( (error) => {
                console.log(error);
            });
        },
        sendFormOrderStates()
        {
            var vm = this;
            this.axios({
                url: 'orders/'+this.orden_id+'/state',
                method: 'POST',
                data: {
                    state: this.current_state
                }
            }).then( () => {
                vm.$bvToast.toast('Estado actualizado correctamente', {
                    title: `Información`,
                    variant: 'success',
                    solid: true,
                    toaster: 'b-toaster-bottom-center'
                });
                vm.fetchData();
            }).catch( (error) => {
                console.log(error);
            });
        },
        fetchCouriers() {
            var vm = this;
            this.axios({
                method: "GET",
                url: "shippings/couriers/data_select_input",
            })
            .then((response) => {
            vm.couriersList = response.data;
            vm.carrierList = response.data;
            vm.carrierList.push({ text: "Automático", value: null });
            })
            .catch((error) => {
            console.log(error);
            });
        },
        sendFormCarrier()
        {
            var vm = this;
            this.axios({
                url: 'orders/'+this.orden_id+'/carrier',
                method: 'POST',
                data: {
                    carrier: this.order_carrier
                }
            }).then( () => {
                vm.$bvToast.toast('Estado actualizado correctamente', {
                    title: `Información`,
                    variant: 'success',
                    solid: true,
                    toaster: 'b-toaster-bottom-center'
                });
                vm.fetchData();
            }).catch( (error) => {
                console.log(error);
            });
        },
        cambiarEstado(datos){
            this.$emit("openDialogCambiarEstadoOrden", datos);
            console.log(datos);
        },
        editarProductos(datos){
            this.$emit("openDialogEditarProductosOrden", datos);
            console.log(datos);
        },
        openShipmentLogRequest(data){
            this.$emit("openDialogShipmentLogRequest", data);
        },
         openShipmentLogResponse(data){
            this.$emit("openDialogShipmentLogResponse", data);
        },
        isValidUrl(urlString){
	  	    var urlPattern = new RegExp('^(https?:\\/\\/)?'+ // validate protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // validate domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // validate OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // validate port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // validate query string
            '(\\#[-a-z\\d_]*)?$','i'); // validate fragment locator
        return !!urlPattern.test(urlString);
        }
    },
    created(){
        
        this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Dashboard Ordenes", route: "/ordenes" },
            { title: "Orden "+this.$route.params.orden, route: "/ordenes/"+this.$route.params.orden },
        ]);
        this.store_country = this.$store.state.passport.user.store_country;
        console.log('Countryrr');
        console.log(this.$store.state.passport.user.store_country);
        this.fetchData();
    },
    watch: {
        $route(to, from) {
         this.fetchData();
        }
    }
}
</script>
<style scoped>
    .v-list-item__title, .v-list-item__subtitle {
        white-space: normal;
    }
</style>