<template>
<v-app>
    <v-dialog v-model="showDialog" max-width="1000px">
        <v-card v-if="showDialog">
            <v-card-title>
                <span>Pedido #{{orden_id}}</span>
            </v-card-title>
            <v-card-text>
                <!-- <div class="row">
                    <div class="col-md-10">
                        <v-combobox label="Productos" clearable :items="listProducts" v-model="selectProduct"></v-combobox>
                    </div>
                    <div class="col-md-2">
                        <v-btn color="success" @click="addProduct" :disabled="!selectProduct || !!$store.state.passport.loadingStack" > Añadir -> </v-btn>
                    </div>
                    
                </div> -->
                <div class="row">
                    <div class="col-md-12">
                        <v-simple-table dense label="Productos">
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th v-for="(h,i) in ['Producto','SKU','EAN','Cantidad (Menor o igual)','Precio total']" :key="i" class="text-left">{{h}}</th>
                                        <th class="text-right">Acciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, index) in order.items" :key="index">
                                        <td>{{ item.name }}</td>
                                        <td>{{ item.sku }}</td>
                                        <td>{{ item.ean }}</td>
                                        <td> <v-text-field type="number" v-model="item.quantity" step="1" min="1":max="getMaxQty(item)" dense @change="checkQty(item)"  > </v-text-field></td>
                                        <td> <v-text-field type="number" v-model="item.price" step="0.00" min="0.01" suffix="$" dense ></v-text-field> </td>
                                        <td class="text-right" v-if="countProducts > 1">
                                            <v-btn @click="eliminarFila(item)" text icon color="red"> <v-icon>mdi-close</v-icon> </v-btn>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </div>
                </div>
                
                
            </v-card-text>
            <v-divider></v-divider>
            <v-card-text>
                <div class="row">
                    <div class="col-md-3">
                        <v-text-field label="Total Productos" suffix="$" required type="number" step="0.01" min="0.00" v-model="total_products"  ></v-text-field>
                    </div>
                    <div class="col-md-3">
                        <v-text-field label="Total Envío" suffix="$" required type="number" step="0.01" min="0.00" v-model="total_shipping"  ></v-text-field>
                    </div>
                    <div class="col-md-3">
                        <v-text-field label="Total Descuentos" suffix="$" required type="number" step="0.01" min="0.00" v-model="total_discount"  ></v-text-field>
                    </div>
                    <div class="col-md-3">
                        <v-text-field label="Total Pagado" suffix="$" required type="number" step="0.01" min="0.00" v-model="total_paid"  ></v-text-field>
                    </div>
                </div>
            </v-card-text>
            <v-card-actions>
                <v-btn @click="showDialog = false" dark color="pink" > Cancelar </v-btn>
                <v-btn color="success" class="btn-starkons-primary" @click="sendForm" :disabled="disableSendFormBtn" >
                    Guardar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</v-app>
</template>

<script>
export default {
    data(){
        return {
            showDialog: false,
            orden_id: undefined,
            store_id: undefined,
            current_state: undefined,
            current_substate: undefined,
            orderStatesList: [],
            orderSubStatesList: [],
            disableSendFormBtn: false,

            listProducts: [],
            selectProduct: undefined,
            order:{
                items: [], // {sku, name, quantity, ean, price}
            },
            total_products: undefined,
            total_shipping: undefined,
            total_discount: undefined,
            total_paid: undefined,
            original_od: [],
            countProducts: 0,
        }
    },
    created(){
            this.$parent.$on('openDialogEditarProductosOrden', (datos) => {
            this.orden_id = datos.order_id;
            this.store_id = datos.store_id;
            this.orderValdidationState = undefined
            this.validStateChange = 1;
            this.order.items = datos.items;
            this.total_products = datos.total_products;
            this.total_shipping = datos.total_shipping;
            this.total_discount = datos.total_discount;
            this.total_paid = datos.total_paid;
            
            console.log('datos', datos);
            //this.current_substate = datos.
            this.fetchData();
            let vm = this;
            console.log('this.order.items', this.order.items);
            vm.original_od = [];
            this.order.items.forEach(e => {
                vm.original_od.push({sku: e.sku, quantity: e.quantity, price: e.price});
            });
            this.countProducts = this.order.items.length;
            console.log('countProducts', this.countProducts);
            console.log('vm.original_od', vm.original_od);

           
        });
    },
    methods: {
        fetchProducts() {
            let vm = this;
            this.axios({
                url: 'order/products_data_list',
                method: 'GET'
            }).then( response => {
                vm.listProducts = response.data;
            }).catch( error => {
                console.log(error);
            });
        },
        eliminarFila(item) {
            const index = this.order.items.indexOf(item);
            this.order.items.splice(index,1);
        },
        addProduct()
        {
            let vm = this;
            this.axios({
                url: 'order/products/'+this.selectProduct.value,
                method: 'GET'
            }).then(response => {
                let datos = response.data.product;
                let busqueda = vm.order.items.find(e => e.sku == datos.sku);
                if(busqueda){
                    busqueda.quantity++;
                    return;
                }
                vm.order.items.push({sku: datos.sku, ean: datos.ean, quantity: 1, name: datos.name, price: datos.price});
            }).catch(error => {
                console.log(error);
            });
        },
        sendForm(){
            var vm = this;
            let ok = true;
            this.order.items.forEach(e => {
                let oq = vm.original_od.find(x => x.sku == e.sku)
                if(oq.quantity < e.quantity){
                    ok = false;
                }
            });
            vm.disableSendFormBtn = true;
            
            
            if(!ok){
                console.log('no se puede');
                // vm.showDialog = false;
                vm.disableSendFormBtn = false;
                vm.$bvToast.toast("No se permite aumentar unidades", {
                    title: `Alerta`,
                    variant: 'warning',
                    solid: true,
                    toaster: 'b-toaster-bottom-center'
                });
            }
            else{
                console.log(vm.order.items);
                this.axios({
                    url: 'orders/'+this.orden_id+'/update_order_details',
                    method: 'POST',
                    data: {
                        items: vm.order.items,
                        totals: {
                            total_products: vm.total_products,
                            total_shipping: vm.total_shipping,
                            total_discount: vm.total_discount,
                            total_paid: vm.total_paid
                        }
                        // state: this.current_state,
                        // substate: this.orderSubStatesList.length ? this.current_substate : null,
                        // validatestatelogistic: this.orderValdidationState ? this.validStateChange : 1
                    }
                }).then( () => {
                    vm.showDialog = false;
                    vm.$emit('success', "Productos modificados correctamente");
                    vm.disableSendFormBtn = false;
                }).catch( () => {
                    //console.log(error);
                    vm.disableSendFormBtn = false;
                });
            }
        },
        fetchData(){
            var vm = this;
            this.fetchProducts();
            vm.showDialog = true; 
        },
        checkQty(e){
            var vm = this;
            // console.log(e);
            // console.log(vm.original_od);
            // let original = vm.original_od.find(x => x.sku == e.sku);
            // console.log(e.quantity);
            // console.log(original.quantity);
            // if(e.quantity > original.quantity){
            //     e.quantity = original.quantity;
            // }
    

            //  console.log(vm.order.items.find(x => x.sku == e.sku));
            //  (vm.order.items.find(x => x.sku == e.sku)).quantity = 6;
            // console.log(vm.order.items.find(x => x.sku == e.sku));
        },
        getMaxQty(e){
            var vm = this;
            let original = vm.original_od.find(x => x.sku == e.sku);
            return original.quantity;
        },
        
    }
     
}
</script>